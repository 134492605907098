::-webkit-scrollbar {
  display: none;
}

.container {
  /* border: white solid 2px; */
  margin: 0;
  padding: 0;
  font-family: "Tungsten Compressed Book", "Tungsten Book";

  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  /* overflow-y: scroll; */
  scroll-behavior: smooth;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  background: #333;
  color: #fff;
  line-height: 1.6;
  height: 100vh;
}

video {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100vh;
  min-width: 100%;
}

@media only screen and (max-device-width: 1024px) {
  .parallax {
    background-attachment: scroll;
  }
}

.fadeIn {
  opacity: 0;
}

section {
  padding: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.arden {
  width: 100%;
  height: 100%;
  z-index: 100;
  font-size: 9rem;
  text-indent: 9vw;
  letter-spacing: 9vw;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  display: flex;
}

.arden .subtitle {
  top: -20px;
  font-size: 4vw;
  text-indent: 4vw;
  letter-spacing: 4vw;
}

.quote {
  font-size: 5rem;
  top: 150vh;
  text-indent: 2vw;
  letter-spacing: 2vw;
  z-index: 100;
}
/*# sourceMappingURL=App.css.map */
